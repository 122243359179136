<template>
	<div id="listCashierBankFills" class="input-heading">
		<h3>List Bank Fills</h3>
		<div v-if="cashierBankFills != []">
			<div id="paging" v-if="cashierBankFills.length > 0">
				<label for="limitOptions">Limit List</label>
				<select v-model="fillsLimit" id="limitOptions">
					<option v-for="(item, index) in limitOptions" :key="index" :value="item.value">{{ item.value }}</option>
				</select>
				<button class="prev-button" id="prev-button" type="button" @click="previousPage($event)">previous</button>
				<button class="next-button" id="next-button" type="button" @click="nextPage($event)">next</button>
				<span>page {{ currentPage }}</span>
			</div>
			<div class="bank-lists-container">
				<div v-if="!getListComplete" id="bank-fills-loading">
					<span class="loading-icon"></span>
					Loading List
				</div>
				<table v-if="cashierBankFills.length > 0" class="cashier-fills">
					<tr class="header-row">
						<th>Fill Id</th>
						<th>Date</th>
						<th>To Bank Amount: Cash</th>
					</tr>
					<tr class="data-row" v-for="(item, index) in cashierBankFills" :key="index">
						<td>{{ item.id }}</td>
						<td>{{ item.date }}</td>
						<td>{{ systemCurrencyTool.formatCurrency(item.toBankCashCOC, systemCurrencyTool.displayType.minorOrFull) }}</td>
					</tr>
				</table>

				<div v-if="!cashierBankFills.length > 0 && getListComplete">
					<h2>No Bank Fills Found</h2>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import router from "@/router/index";
import { onBeforeUnmount } from "vue";
export default {
	name: "ListCashierBankFills",
	props: {
		cashierState: {
			type: Object,
		},
		appDataBus: {
			type: Object,
		},
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			toBankAmount: {},
			fillsOffset: 0,
			fillsLimit: 20,
			currentPage: 1,
			isLastPage: false,
			cashierBankFills: [],
			getListComplete: false,
			currencyInfo: this.cashierState.currencyInfo,
		};
	},
	watch: {
		fillsLimit() {
			this.currentPage = 1;
			this.fillsOffset = null;
			this.fillsLimit = parseInt(this.fillsLimit);
			this.listCashierBankFills(this.fillsLimit, this.fillsOffset);
		},
	},
	methods: {
		async listCashierBankFills(fillsLimit = null, fillsOffset = null) {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				return false;
			}

			this.getListComplete = false;
			if (fillsLimit) this.fillsLimit = fillsLimit;

			if (fillsOffset) this.fillsOffset = fillsOffset;

			this.cashierBankId = this.cashierBankId || this.cashierState.cashierBankId;

			if (!this.cashierBankId) {
				let bankList = await sharedScripts.getBanksList(this);

				let bankListStatus = sharedScripts.checkFetchErrors(bankList);

				if (bankListStatus && !bankListStatus.ok) {
					this.eventBus.emit("updateStatus", bankListStatus);
					return;
				}
				let openBank = bankList.find((element) => element.cashierUserId == this.cashierState.userId);
				this.cashierBankId = openBank.id;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.cashierState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`api/v1/cashier/bank/${this.cashierBankId}/fills`, this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.fillsOffset) params.set("offset", this.fillsOffset);

			// get one more item than requested to see if a second page exists
			if (this.fillsLimit) params.set("limit", this.fillsLimit + 1);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.code === 409 || fetchStatus.code === 401) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.isLastPage = dataJson?.length <= this.fillsLimit;

				if (Array.isArray(dataJson)) {
					// remove extra item if a second page exists
					if (!this.isLastPage) dataJson.pop();
					dataJson.forEach((item) => {
						item.date = new Date(item.date).toLocaleTimeString([], this.dateOptions);
					});
				} else {
					this.status.message = `Server returned code ${dataJson.status ? dataJson.status : dataJson} but did not return an Fills List`;
					this.eventBus.emit("updateStatus", this.status);
				}

				this.getListComplete = true;
				this.cashierBankFills = dataJson;
			} catch (e) {
				console.error(e);
			}
		},
		async previousPage(e) {
			if (this.currentPage == 1) return;
			this.currentPage--;
			this.fillsOffset = this.fillsOffset - this.fillsLimit;
			this.listCashierBankFills(this.fillsLimit, this.fillsOffset);
		},
		async nextPage(e) {
			if (this.isLastPage) return;
			this.fillsOffset = this.fillsOffset + this.fillsLimit;
			this.currentPage++;
			this.listCashierBankFills(this.fillsLimit, this.fillsOffset);
		},
	},
	async mounted() {
		this.listCashierBankFills(this.fillsLimit, this.fillsOffset);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#listCashierBankFills {
	padding: 15px;
	height: calc(100vh - 370px);
	overflow: hidden;
}
.bank-lists-container {
	height: calc(100vh - 31em);
	overflow: hidden auto;
}

.cashier-fills {
	width: 100%;
	text-align: center;
	padding-bottom: 15px;
}

.cashier-fills tr {
	transition: background-color 0.3s;
}

.cashier-fills tr:nth-child(2n) {
	background-color: #414650;
}

.cashier-fills tr.data-row:hover {
	background-color: #dfdfdf;
	color: #000;
}

.debug {
	display: none;
}

.open-text {
	padding: 1px 5px;
	background-color: #0f0;
	color: #000;
	font-weight: 700;
}

#bank-fills-loading {
	text-align: center;
	display: grid;
	position: absolute;
	margin: auto;
	width: 100%;
	z-index: 200;
	background-color: rgb(0 0 0 / 80%);
	padding: 60px;
}
</style>
