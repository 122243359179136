<template>
	<div id="getAccountAccessCode" class="input-heading">
		<h3>Open Player Account</h3>
		<div class="input-section">
			<div>
				<label for="phoneNumber">Player Phone Number</label>
				<input
					class="max-fifty"
					type="number"
					name="phoneNumber"
					@click="numInputPadInit('phoneNumber')"
					v-model="phoneNumber"
					placeholder="Player Phone Number"
					autocomplete="off"
					:maxlength="phoneNumberLength"
					onfocus="blur();"
				/>
			</div>
			<div>
				<button class="get-code btn" @click="accountAccessCodePost()">Get Account Access Code</button>
			</div>
		</div>
	</div>
	<transition name="fade">
		<NumInputPad
			v-if="inputConfig.inputField"
			:inputConfig="inputConfig"
			:currencyInfo="cashierState.currencyInfo"
			:inGameCurrencyTool="inGameCurrencyTool"
			:systemCurrencyTool="systemCurrencyTool"
		/>
	</transition>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import NumInputPad from "@/components/NumInputPad.vue";
import router from "@/router/index";
import { onBeforeUnmount } from "vue";
export default {
	name: "GetAccountAccessCode",
	inheritAttrs: false,
	props: {
		cashierState: Object,
		appDataBus: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {
		NumInputPad,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			inputConfig: Object.assign({}, this.globalInputConfig),
			playerAccount: {},
			phoneNumber: null,
			playerDisplayName: "",
			accountAccessCode: 0,
			casinoId: 0,
			allowCashIn: true,
			allowCashOut: true,
			allowLockRelease: true,
			smsAttemptFail: false,
		};
	},
	methods: {
		submit(e) {
			e.preventDefault();
			this.accountAccessCodePost();
		},
		numInputPadInit(inputField) {
			this.inputConfig.inputField = inputField;
			this.inputConfig.keypadInput = this.phoneNumber;
		},
		async accountAccessCodePost() {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				return false;
			}

			let body = {
				CasinoId: this.cashierState.casinoId,
				PhoneNumber: this.phoneNumber?.toString() || this.cashierState?.playerPhone?.toString(),
				AllowCashIn: this.allowCashIn,
				AllowCashOut: this.allowCashOut,
				AllowLockRelease: this.allowLockRelease,
			};

			let requestUrl = new URL("/api/v1/cashier/code", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.cashierState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					fetchStatus.message = `Request Account Access Code failed: ${fetchStatus.message ? fetchStatus.message : fetchStatus.code}`;
					this.eventBus.emit("closeKeypad");
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.code === 409 || fetchStatus.code === 401) this.eventBus.emit("forceLogout");
					return;
				}

				let getAccountAccessCode = await response.json();

				if (getAccountAccessCode.status == "UserNotFound") {
					this.status.message = "User Not Found";
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
					this.eventBus.emit("closeKeypad");
					return;
				} else if (getAccountAccessCode.status !== "Success") {
					this.status.ok = false;
					this.status.message = getAccountAccessCode.status;
					this.eventBus.emit("updateStatus", this.status);
					this.eventBus.emit("closeKeypad");
					this.smsAttemptFail = true;
					if (this.status.message !== "FailedToSend") return;
				}

				let _state = this.cashierState;
				this.playerAccount.accessCodeConfirmed = false;
				this.playerAccount.accountAccessCode = null;
				this.playerAccount.user = getAccountAccessCode.user;
				this.playerAccount.account = getAccountAccessCode.account;
				this.playerAccount.buyInOffers = getAccountAccessCode.buyInOffers;

				_state.playerAccount = this.playerAccount;

				_state.forceChildComponent = "OpenPlayerAccount";

				this.eventBus.emit("updateCashierState", _state);
				this.eventBus.emit("getAccountAccessCodeSuccess", this.smsAttemptFail);
				if (!this.smsAttemptFail) {
					this.status.ok = true;
					this.status.message = `Account Access Code sent to ${this.phoneNumber}`;
					this.eventBus.emit("updateStatus", this.status);
				}
				return;
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
	},
	created() {
		this.playerDisplayName = this.cashierState?.playerAccount?.user?.displayName;
		this.playerPhone = this.cashierState?.playerAccount?.user?.playerPhone;
		let thisInstance = this;
		this.eventBus.on("closeKeypad", () => {
			thisInstance.inputConfig.inputField = null;
		});
		this.eventBus.on("commitInputFromNumPad", (payload) => {
			thisInstance.phoneNumber = payload;
			thisInstance.playerPhone = payload;
			thisInstance.accountAccessCodePost();
		});
		onBeforeUnmount(() => {
			thisInstance.inputConfig.inputField = null;
			thisInstance.eventBus.off("closeKeypad");
			thisInstance.eventBus.off("commitInputFromNumPad");
		});
	},
	mounted() {
		this.playerPhone = this.phoneNumber = this.cashierState?.playerAccount?.playerPhone || 0;
		if (this.playerPhone && !this.cashierState?.playerAccount?.accessCodeConfirmed) {
			this.accountAccessCodePost();
		}
		if (this.cashierState.forceChildComponent === "GetAccountAccessCode") {
			this.accountAccessCodePost();
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#getAccountAccessCode {
	/* padding-bottom: 20em; */
	height: calc(100vh - 21em);
	overflow: hidden auto;
}

#getAccountAccessCode::-webkit-scrollbar-track {
	box-shadow: inset 1px 1px 6px rgb(0 0 0 / 75%);
	border-radius: 16px;
	background-color: #434250;
}

#getAccountAccessCode::-webkit-scrollbar {
	width: 32px;
}

#getAccountAccessCode::-webkit-scrollbar-thumb {
	border-radius: 16px;
	box-shadow: inset -2px -2px 6px rgb(0 0 0 / 75%);
	background-color: #bccfe5;
}

#getAccountAccessCode .input-section {
	background-color: #24272c;
	padding: 30px;
	border-radius: 8px;
	box-shadow: 2px 3px 8px rgb(0 0 0 / 75%), inset 4px 3px 10px 4px rgb(42 63 88 / 30%), inset -2px -2px 10px 2px rgb(0 0 0 / 30%);
}

.get-code {
	margin: 5px auto;
}
</style>
