<template>
	<div class="summary-container">
		<div v-if="serverBusy" id="summary-loading">
			<span class="loading-icon"></span>
			{{ languageStrings.loadingCashierSummary }}
		</div>
		<div v-else class="table-container">
			<h2>{{ languageStrings.cashier }}: {{ cashierBankSummary.cashierDisplayName }}</h2>
			<table class="cashier-summary">
				<tr>
					<th>{{ languageStrings.bankId }}</th>
					<td>{{ cashierBankSummary.cashierBankId }}</td>
				</tr>
				<tr>
					<th>{{ languageStrings.casinoName }}</th>
					<td>{{ casinoList.filter((casino) => cashierBankSummary?.casinoId == casino?.id)[0]?.name }}</td>
				</tr>
				<tr>
					<th>{{ languageStrings.openDateText }}</th>
					<td>{{ new Date(cashierBankSummary.openDate).toLocaleTimeString([], dateOptions) }}</td>
				</tr>
				<tr v-if="cashierBankSummary.closeDate">
					<th>{{ languageStrings.closeDateText }}</th>
					<td>{{ new Date(cashierBankSummary.closeDate).toLocaleTimeString([], dateOptions) }}</td>
				</tr>
				<tr>
					<th>{{ languageStrings.openingBalanceCash }}</th>
					<td>
						{{
							systemCurrencyTool.formatCurrency(
								cashierBankSummary.openingBalanceCashCOC,
								systemCurrencyTool.displayType.minorWholeOrFull
							)
						}}
					</td>
				</tr>
				<tr>
					<th>{{ languageStrings.intermediateFillsCash }}</th>
					<td>
						{{
							systemCurrencyTool.formatCurrency(
								cashierBankSummary.intermediateFillsCashCOC,
								systemCurrencyTool.displayType.minorWholeOrFull
							)
						}}
					</td>
				</tr>
				<tr v-if="cashierBankSummary.closingBalanceCashCOC">
					<th>{{ languageStrings.closingBalanceCash }}</th>
					<td>
						{{
							systemCurrencyTool.formatCurrency(
								cashierBankSummary.closingBalanceCashCOC,
								systemCurrencyTool.displayType.minorWholeOrFull
							)
						}}
					</td>
				</tr>
				<tr>
					<th>{{ languageStrings.toPlayerCash }}</th>
					<td>
						{{ systemCurrencyTool.formatCurrency(cashierBankSummary.toPlayerCashCOC, systemCurrencyTool.displayType.minorWholeOrFull) }}
					</td>
				</tr>
				<tr>
					<th>{{ languageStrings.fromPlayerCash }}</th>
					<td>
						{{ systemCurrencyTool.formatCurrency(cashierBankSummary.fromPlayerCashCOC, systemCurrencyTool.displayType.minorWholeOrFull) }}
					</td>
				</tr>
				<tr>
					<th>{{ languageStrings.toPlayerAccountsRegular }}</th>
					<td>
						{{
							inGameCurrencyTool.formatFromAU(
								cashierBankSummary.toPlayerAccountsRegularAU,
								inGameCurrencyTool.displayType.minorWholeOrFull
							)
						}}
					</td>
				</tr>
				<tr>
					<th>{{ languageStrings.fromPlayerAccountsRegular }}</th>
					<td>
						{{
							inGameCurrencyTool.formatFromAU(
								cashierBankSummary.fromPlayerAccountsRegularAU,
								inGameCurrencyTool.displayType.minorWholeOrFull
							)
						}}
					</td>
				</tr>
				<tr>
					<th>{{ languageStrings.toPlayerAccountsPromo }}</th>
					<td>
						{{
							inGameCurrencyTool.formatFromAU(
								cashierBankSummary.toPlayerAccountsPromoAU,
								inGameCurrencyTool.displayType.minorWholeOrFull
							)
						}}
					</td>
				</tr>
				<!-- <tr>
					<th>From Player Accounts Promo</th>
					<td>{{ inGameCurrencyTool.formatFromAU(cashierBankSummary.fromPlayerAccountsPromoAU, inGameCurrencyTool.displayType.minorWholeOrFull) }}</td>
				</tr> -->
			</table>

			<button class="btn" @click="closeModal()">{{ languageStrings.closeText }}</button>
		</div>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import { onBeforeUnmount } from "vue";

export default {
	name: "CashierBankSummary",
	inheritAttrs: false,
	props: {
		casinoList: Array,
		cashierState: Object,
		cashierBankId: Number,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			cashierBankSummary: {},
			body: document.querySelector("body"),
			controller: null,
			serverBusy: false,
			currencyInfo: this.cashierState.currencyInfo,
		};
	},
	created() {
		onBeforeUnmount(() => {
			this.body.onkeydown = null;
			if (this.serverBusy === true) this?.controller?.abort();
		});
	},
	mounted() {
		this.getCashierSummary(this.cashierBankId);
		this.body.onkeydown = (e) => {
			if (e.key === "Escape") this.closeModal();
		};
	},
	methods: {
		async getCashierSummary(cashierBankId) {
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				return false;
			}

			this.controller = new AbortController();
			let signal = this.controller.signal;

			try {
				const response = await this.getCashierBankSummary(this, cashierBankId, signal);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.status.ok = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.code === 409 || fetchStatus.code === 401) this.eventBus.emit("forceLogout");
					this.serverBusy = false;
					// return false;
				}

				this.cashierBankSummary = response;

				this.serverBusy = false;
			} catch (e) {
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.serverStatus.serverBusy = false;
				this.serverBusy = false;
				this.eventBus.emit("closeCashierBankSummaryModal");
			}
		},
		closeModal() {
			this.eventBus.emit("closeCashierBankSummaryModal");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#summary-loading {
	position: fixed;
	right: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(1px);
	height: 100vh;
}

.summary-container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: grid;
	align-items: center;
	justify-content: center;
	background-color: rgb(0 0 0 / 60%);
	backdrop-filter: blur(6px);
}

.cashier-summary {
	margin: auto;
	border-collapse: collapse;
}

.table-container {
	background-color: #000;
	padding: 0 30px 30px;
	border-radius: 16px;
}

.table-container .btn {
	margin: 10px auto 5px;
}

.cashier-summary * {
	border: 1px #fff solid;
	border-collapse: collapse;
	padding: 5px 10px;
}

.cashier-summary th {
	text-align: left;
}

.cashier-summary td {
	text-align: right;
}
</style>
