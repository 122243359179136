<template>
	<div id="openPlayerAccount" class="input-heading">
		<h3>Enter Account Access Code</h3>
		<div class="input-section">
			<div>
				<label for="accountAccessCode">Account Access Code</label>
				<input
					autofocus
					class="max-fifty"
					type="number"
					name="accountAccessCode"
					@click="numInputPadInit('accountAccessCode')"
					@keyup.enter="submit($event)"
					v-model="accountAccessCode"
					placeholder="Account Access Code"
					autocomplete="off"
					onfocus="blur();"
				/>
			</div>
			<div class="btn-container">
				<button class="btn" @click="requestOpenPlayerAccount()">Open Player Account</button>
				<button class="btn" @click="retrySMS()">Resend Access Code</button>
			</div>
			<p v-if="cashierState?.playerAccount?.playerPhone" style="text-align:center">SMS sent to {{ cashierState.playerAccount.playerPhone }}</p>
			<p v-if="accessCodeError != ''" style="text-align:center;color:red;font-weight:700;">Error: {{ accessCodeError }}</p>
		</div>
	</div>
	<transition name="fade">
		<NumInputPad
			v-if="inputConfig.inputField"
			:inputConfig="inputConfig"
			:currencyInfo="cashierState.currencyInfo"
			:inGameCurrencyTool="inGameCurrencyTool"
			:systemCurrencyTool="systemCurrencyTool"
		/>
	</transition>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import NumInputPad from "@/components/NumInputPad";
import { onBeforeUnmount } from "vue";
export default {
	name: "OpenPlayerAccount",
	inheritAttrs: false,
	props: {
		cashierState: Object,
		appDataBus: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {
		NumInputPad,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			inputConfig: Object.assign({}, this.globalInputConfig),
			playerUserId: 0,
			regularAU: 0,
			promoAU: 0,
			phoneNumber: 0,
			displayName: "",
			playerDisplayName: "",
			playerPhone: "",
			accountAccessCode: 0,
			casinoId: 0,
			accessCodeError: "",
			accessCodeConfirmed: false,
			showScanner: false,
		};
	},
	methods: {
		submit(e) {
			e.preventDefault();
			this.requestOpenPlayerAccount();
		},
		retrySMS() {
			this.eventBus.emit("retrySMS");
		},
		numInputPadInit(inputField) {
			this.inputConfig.inputField = inputField;
			this.inputConfig.keypadInput = this.accountAccessCode;
		},
		async testCashierAccessCode(code) {
			this.accessCodeError = "";

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				return false;
			}

			let body = {
				UserId: this.cashierState.playerAccount.user.id,
				CasinoId: this.cashierState.casinoId,
				Code: code,
			};

			let requestUrl = new URL("/api/v1/cashier/code/test", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.cashierState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					fetchStatus.message = `Code failed: ${fetchStatus.message ? fetchStatus.message : fetchStatus.code}`;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.code === 409 || fetchStatus.code === 401) this.eventBus.emit("forceLogout");
					return false;
				}

				let verifyCode = await response.json();
				let error = sharedScripts.checkSuccessErrors(verifyCode, this.languageErrorStrings);

				if (verifyCode.status !== "Success") {
					this.status.ok = false;
					this.status.message = verifyCode.status;
					this.eventBus.emit("updateStatus", this.status);
					this.accessCodeError = verifyCode.status;
					this.inputConfig.inputField = null;
					return false;
				}

				let _state = this.cashierState;
				_state.playerAccount.accountAccessCode = code;
				this.eventBus.emit("updateCashierState", _state);

				this.accessCodeError == "";
				return verifyCode;
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
				return false;
			}
		},
		async requestOpenPlayerAccount() {
			if (this.accountAccessCode.toString().length !== this.inputConfig.limitNumber) {
				this.status.ok = false;
				this.status.message = "Please enter the six digit access code.";
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}
			
			let isCorrectCode = await this.testCashierAccessCode(this.accountAccessCode);

			if (isCorrectCode !== false) {
				let STATE = this.cashierState;
				STATE.playerAccount.accountAccessCode = this.accountAccessCode;
				STATE.playerAccount.accessCodeConfirmed = true;

				STATE.transferFundsIn = true;
				STATE.forceChildComponent = "BuyIns";
				STATE.currentComponentId = "FundsTransferIn";
				this.status.message = `${STATE.playerAccount.user.displayName} Account Open Success`;
				this.status.ok = true;
				this.eventBus.emit("updateStatus", this.status);

				this.accessCodeConfirmed = true;
				this.eventBus.emit("updateCashierState", STATE);
				// this.openPlayerAccount();
			}
		},
		async openPlayerAccount() {
			let STATE = this.cashierState;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.cashierState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/user/${STATE.playerAccount.playerUserId}`, this.rabbitsfootHostUrl);
			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					fetchStatus.message = `Open player account failed: ${fetchStatus.message ? fetchStatus.message : fetchStatus.code}`;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.code === 409 || fetchStatus.code === 401) this.eventBus.emit("forceLogout");
					return false;
				}

				let userIdentity = await response.json();

				if (userIdentity) {
					this.playerDisplayName = userIdentity.displayName;
					this.playerUserId = userIdentity.id;
					this.playerPhone = userIdentity.phoneNumber;
				}
				let fundsRequestUrl = new URL(`/api/v1/funds/user/${this.playerUserId}`, this.rabbitsfootHostUrl);

				let params = fundsRequestUrl.searchParams;
				params.set("casinoId", this.cashierState.casinoId);
				fundsRequestUrl.search = params.toString();

				let fundsRequest = new Request(fundsRequestUrl.toString(), {
					method: "GET",
					headers: headerObj,
				});

				let getFunds = await fetch(fundsRequest);

				let fundsFetchStatus = sharedScripts.checkFetchErrors(getFunds);

				if (fundsFetchStatus && !fundsFetchStatus.ok) {
					this.status.message = `Open player account failed: ${
						fundsFetchStatus.message ? fundsFetchStatus.message : fundsFetchStatus.code
					}`;
					this.eventBus.emit("updateStatus", this.status);
					return;
				}

				getFunds = await getFunds.json();

				let thisCasinoAccount = {};

				if (Array.isArray(getFunds)) {
					thisCasinoAccount = getFunds.find((item) => item.casinoId == STATE.casinoId);
				} else {
					this.eventBus.emit("updateStatus", fetchStatus);
				}

				// This model is based on the JSON returned by
				// "api/v1/cashier/code/lookup" endpoint but also include additional properties
				// some of the properties that are added in are redundant but I didn't want to
				// break the alternative SMS fallback which creates a slightly different model.
				// At some point this caould be cleaned up. The SMS fallback shouldn't be the common use case.
				this.playerAccount = {
					accessCodeConfirmed: this.accessCodeConfirmed,
					accountAccessCode: this.accountAccessCode,
					code: this.accountAccessCode,
					user: {
						displayName: this.playerDisplayName,
						id: thisCasinoAccount?.userId || this.playerUserId,
						phoneNumber: this.playerPhone,
					},
					account: {
						userId: thisCasinoAccount?.userId || this.playerUserId,
						casinoId: thisCasinoAccount?.casinoId || "",
						fundsLocks: thisCasinoAccount?.fundsLocks || [],
						balance: thisCasinoAccount?.balance || {
							promoAU: 0,
							regularAU: 0,
						},
					},
				};

				STATE.playerAccount = this.playerAccount;
				STATE.transferFundsIn = true;
				STATE.forceChildComponent = "FundsTransfer";
				STATE.currentComponentId = "FundsTransferIn";
				this.eventBus.emit("updateCashierState", STATE);
				this.status.message = `${STATE.playerAccount.user.displayName} Account Open Success`;
				this.status.ok = true;
				this.eventBus.emit("updateStatus", this.status);
				return getFunds;
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.userMustDismiss = true;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
	},
	created() {
		this.inputConfig.limitNumber = 6; // inputConfig.limitNumber to 6 digit confirm code.
		this.playerDisplayName = this.cashierState.playerAccount.user.displayName;
		this.playerPhone = this.cashierState.playerAccount.user.playerPhone;
		let thisInstance = this;
		this.eventBus.on("closeKeypad", () => {
			thisInstance.inputConfig.inputField = null;
		});
		onBeforeUnmount(() => {
			thisInstance.inputConfig.inputField = null;
			thisInstance.eventBus.off("closeKeypad");
		});
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#openPlayerAccount {
	padding-bottom: 20em;
	height: calc(100vh - 20em);
	overflow: hidden auto;
}

#openPlayerAccount .btn-container {
	display: flex;
	/* flex-direction: column; */
	margin: auto;
}

#openPlayerAccount::-webkit-scrollbar-track {
	box-shadow: inset 1px 1px 6px rgb(0 0 0 / 75%);
	border-radius: 16px;
	background-color: #434250;
}

#openPlayerAccount::-webkit-scrollbar {
	width: 32px;
}

#openPlayerAccount::-webkit-scrollbar-thumb {
	border-radius: 16px;
	box-shadow: inset -2px -2px 6px rgb(0 0 0 / 75%);
	background-color: #bccfe5;
}

#openPlayerAccount .input-section {
	background-color: #24272c;
	padding: 30px;
	border-radius: 8px;
	box-shadow: 2px 3px 8px rgb(0 0 0 / 75%), inset 4px 3px 10px 4px rgb(42 63 88 / 30%), inset -2px -2px 10px 2px rgb(0 0 0 / 30%);
}
</style>
